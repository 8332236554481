<template>
  <!-- Main content -->
  <section class="content">
    <!-- 搜索 -->
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="推广位id：">
          <el-input v-model="search_pid" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach(1)">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加按钮 -->
    <div class="btn-box">
      <!-- <el-button type="primary" size="mini" @click="add"
        >添加</el-button
      > -->
    </div>
    <!-- 表单 -->
    <el-table
      v-loading="loading"
      :data="blackList"
      border
      style="width: 100%"
    >
      <el-table-column
        fixed
        label="编号"
        prop="id"
        width="50"
      ></el-table-column>
<el-table-column label="推广位id" prop="pid" width="300"></el-table-column>
<el-table-column label="佣金比例" prop="commission_rate"></el-table-column>
<!-- <el-table-column label="类型" prop="commission_rate"></el-table-column> -->
<el-table-column label="备注" prop="remark"></el-table-column>
<el-table-column label="最后修改时间">
  <template slot-scope="item">
    {{item.row.update_time | dateTime}}
  </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="item">
          <el-button
            type="primary"
            size="mini"
            @click="getcommissionConfig(item.row.id)"
            >修改</el-button
          >
        </template>
</el-table-column>
</el-table>
<!-- 分页 -->
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange" :current-page="page">
</el-pagination>
<!-- 修改添加弹窗 -->
<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal="false">
    <el-form>
        <el-form-item label="推广位ID：">
            <el-input v-model="pid" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="佣金比例：">
            <el-input v-model="commission_rate" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="备注：">
            <el-input v-model="remark" placeholder=""></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    let common = JSON.parse(localStorage.getItem("common"));
    import * as api from "@/config/api";
    export default {
        name: "gzhCommission",
        data() {
            return {
                loading: false,
                pagesize: 10,
                page: 1,
                total: 0,

                dialog: false,
                blackList: [],

                search_pid: "",

                id: "",
                pid: '',
                commission_rate: '',
                type: '',
                remark: '',

            };
        },
        filters: {
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        watch: {},
        mounted() {
            this.commissionConfigList();
        },
        methods: {

            getTime(time) {
                let Time = new Date(time * 1000);
                return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
            },
            // 获取列表
            commissionConfigList(page, size) {
                api.commissionConfigList({
                        page: page ? page : this.page,
                        pagesize: size ? size : this.pagesize,
                        pid: this.search_pid,
                    },
                    (res) => {
                        this.blackList = res.data.list;
                        this.total = Number(res.data.count);
                    }
                );
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val;
                this.commissionConfigList();
            },
            currentChange(val) {
                this.page = val;
                this.commissionConfigList();
            },
            //搜索
            seach(page) {
                this.page = page;
                this.commissionConfigList(page);
            },
            reset(page) {
                this.search_id = "";
                this.page = page;
                this.commissionConfigList(page);
            },

            // 添加选品
            add() {
                this.id = ""
                this.pid = ''
                this.commission_rate = ''
                this.type = ''
                this.remark = ''
                this.dialog = true;

            },
            getcommissionConfig(id) {
                api.getcommissionConfig({
                    id
                }, res => {
                    this.id = res.data.id
                    this.pid = res.data.pid
                    this.commission_rate = res.data.commission_rate
                    this.type = res.data.type
                    this.remark = res.data.remark
                    this.dialog = true;
                })
            },
            // 修改、添加频道分类
            alter() {
                api.updatecommissionConfig({
                        id: this.id,
                        pid: this.pid,
                        commission_rate: this.commission_rate,
                        type: this.type,
                        remark: this.remark,
                    },
                    (res) => {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        this.dialog = false;
                        this.commissionConfigList();
                    }
                );
            },
            delshopBlackList(id) {
                this.$confirm("是否确认删除商铺吗?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        api.delshopBlackList({
                                id: id,
                            },
                            (res) => {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.commissionConfigList();
                            }
                        );
                    })
                    .catch(() => {});
            },
        },
    };
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-image img {
        height: 40px;
    }
    
    .el-image-viewer__canvas img {
        width: auto;
        height: auto;
    }
</style>